<template>
  <div class="safe-area relative">
    <LinerBackground/>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">

</script>

<style lang="less" scoped>
.safe-area {
    margin: 0 auto;
    @apply px-20px xl:px-0 w-full max-w-1200px;

    section {
      @apply mb-80px sm:mb-160px;
    }
  }
</style>
