<template>
  <div class="bg1"></div>
  <div class="bg2"></div>
</template>

<script setup lang="ts">

</script>

<style lang="less" scoped>
.bg1 {
    position: absolute;
    left: 190px;
    top: -500px;
    width: 655px;
    height: 255px;
    border-radius: 255px;
    background: black;
    opacity: 1;
    box-shadow: #DEDFFF 20px 300px 640px;
    // transform: rotate(0deg);
    transition: box-shadow 0.3s ease 0s;
    z-index: 0;

    @media screen and (max-width: 640px) {
      width: 50%;
      left: 0;
    }
  }

  .bg2 {
    position: absolute;
    right: 0;
    top: -600px;
    width: 455px;
    height: 255px;
    border-radius: 255px;
    background: black;
    opacity: 0.8;
    box-shadow: #98F5FF 20px 300px 540px;
    // transform: rotate(0deg);
    transition: box-shadow 0.3s ease 0s;
    z-index: 0;

    @media screen and (max-width: 640px) {
      width: 50%;
      right: 0;
    }
  }
</style>
